import React, { useState, useEffect } from "react";
import Timer from "../Components/LabToDyesComponents/Timer";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import LABTableHeader from "../Components/LabToDyesComponents/LABTableHeader";
import LABTableRow from "../Components/LabToDyesComponents/LABTableRow";
import { lab2rgb } from "../Helpers/Helpers";
import { Filter, Measure } from "@nike/nike-design-system-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ButtonStyled } from "@nike/nike-design-system-components";
import { useOktaAuth } from "@okta/okta-react";
import { parseUserGroup } from "../Helpers/Helpers";
import * as XLSX from 'xlsx';
import { apiUrl } from "../Config/oAuthConfig";

const FromLab = (props) => {
	const { oktaAuth, authState } = useOktaAuth();
	const myGroups =
		!authState || !authState.idToken ? null : parseUserGroup(authState);

	const [L, setL] = useState(0);
	const [a, seta] = useState("0");
	const [b, setb] = useState("0");
	const [npa, setNpa] = useState(0.15);
	const [dyetbac, setDyetbac] = useState(2);
	const [dyecombination, setDyecombinations] = useState(2);
	const [R, setR] = useState(0);
	const [G, setG] = useState(0);
	const [B, setB] = useState(0);
	const [buttonDisabled, setButtonDisabled] = React.useState(false);
	const [rows, setRows] = useState([]);
	const [timer, setTimer] = useState(5);
	const [token, setToken] = React.useState("");
	const [helperConfig, setHelperConfig] = useState(null);
	const [resultFileName, setResultFileName] = useState(null);
	const [url, setUrl] = useState("");
	
	useEffect(() => {
		if (authState.isAuthenticated) {
			const token = JSON.parse(window.localStorage["okta-token-storage"])
				.accessToken.accessToken;
			setToken(token);
			setUrl(apiUrl)
		} else {
			console.log("no token found");
			oktaAuth.signInWithRedirect();
		}
	}, [authState.isAuthenticated, oktaAuth]);
	
	useEffect(() => {
		if(props.location && props.location.state){
			setL(props.location.state.l)
			seta(props.location.state.a)
			setb(props.location.state.b)
			let RGB = lab2rgb(props.location.state.l, props.location.state.a, props.location.state.b)
			setR(RGB.R)
			setG(RGB.G)
			setB(RGB.B)
		}
	},[])

	useEffect(() => {
		if (token) {
			fetch(url + "/getHelperConfig", {
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					setHelperConfig(JSON.parse(data));
				})
				.catch((error) => console.log(error));
		}
	}, [token, url]);

	const createAdminData = (
		prediction,
		npa,
		tbac,
		dye1,
		dye1conc,
		dye2,
		dye2conc,
		dye3,
		dye3conc,
		L,
		a,
		b,
		dE,
		R,
		G,
		B,
		DL,
		Da,
		Db,
		umean,
		uL,
		ua,
		ub
	) => {
		return {
			prediction,
			npa,
			tbac,
			dye1,
			dye1conc,
			dye2,
			dye2conc,
			dye3,
			dye3conc,
			L,
			a,
			b,
			dE,
			R,
			G,
			B,
			DL,
			Da,
			Db,
			umean,
			uL,
			ua,
			ub,
		};
	};

	const createUserData = (
		prediction,
		npa,
		tbac,
		dye1,
		dye1conc,
		dye2,
		dye2conc,
		dye3,
		dye3conc,
		L,
		a,
		b
	) => {
		return {
			prediction,
			npa,
			tbac,
			dye1,
			dye1conc,
			dye2,
			dye2conc,
			dye3,
			dye3conc,
			L,
			a,
			b,
		};
	};

	const submitForm = (event) => {
		setButtonDisabled(true);
		setRows([]);

		let filename =
			helperConfig.dyeList.toString() +
			"_" +
			helperConfig.iris.toString() +
			"_" +
			dyecombination.toString() +
			"_" +
			L.toString() +
			"_" +
			a.toString() +
			"_" +
			b.toString() +
			"_" +
			npa.toString() +
			"_" +
			dyetbac.toString() +
			"_" +
			dyecombination.toString() +
			".json";

		let raw = JSON.stringify({
			requested_dyes: dyecombination,
			L: L,
			a: a === "" || a === "-" ? 0 : a,
			b: b === "" || b === "-" ? 0 : b,
			npa: npa,
			dyetbac: dyetbac,
			dyecombination: dyecombination,
			labhandler: window.location.href.includes("irisairmi.nike.com")
				? "prod"
				: window.location.href.includes("irisairmiqa.nike.com") ? "stage" : "dev",
		});

		let requestOptions = {
			method: "POST",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
				"Content-Type":'application/json'
			}),
			body: raw,
		};

		event.preventDefault();

		getColorToDyesData(filename, requestOptions);
	};

	const pollingColorToDyesData = (message, time) => {
		setTimeout(() => {
			let counter = 0 ;
			fetch(url + "/readColor?filename=" + message, {
				method: "GET",
				headers: new Headers({
					Accept: "*/*",
					Authorization: `Bearer ${token}`,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					if (data["Error"] && data["Error"]["Code"] === "NoSuchKey") {
						pollingColorToDyesData(message, 30000);
						console.log("Polling again in 30 seconds");
					} else {
						handleResponse(JSON.parse(data));
					}
				})
				.catch((error) => {
					console.log(error);
					if(counter < 5){
						pollingColorToDyesData(message, 30000);
						console.log("Polling again in 30 sec");
						counter++;
					}
				});
		}, time);
	};

	const getColorToDyesData = (message, requestOptions) => {
	
		fetch(url + "/readColor?filename=" + message, {
			method: "GET",
			headers: new Headers({
				Accept: "*/*",
				Authorization: `Bearer ${token}`,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data["Error"] && data["Error"]["Code"] === "NoSuchKey") {
					console.log(requestOptions.body)
					fetch(url + "/colorToDye", requestOptions)
						.then((result) => {
							console.log("Running Lab To Dyes Function");
							pollingColorToDyesData(message, 1000);
							return result.json();
						})
						.then((data) => {
							//pollingColorToDyesData(message, 30000);
						})
						.catch((error) => {
							console.log(error)
							pollingColorToDyesData(message, 30000);
						}
						);
				} else {
					handleResponse(JSON.parse(data));
				}
			})
			.catch((error) => console.log(error));
	};

	const handleDownload = () => {
		const resData = rows;

		const newResData = resData.map(({ 
			"prediction": prediction,
			"npa": npa,
			"tbac": tbac,
			"dye1": dye1,
			"dye1conc": dye1conc,
			"dye2": dye2,
			"dye2conc": dye2conc,
			"dye3": dye3,
			"dye3conc": dye3conc,
			"L": L,
			"a": a,
			"b": b,
			"dE": dE,
			"R": R,
			"G": G,
			"B": B,
			"DL": DL,
			"Da": Da,
			"Db": Db,
			"umean": umean,
			"uL": uL,
			"ua": ua,
			"ub": ub,
			...rest
		}) => ({
			"Rank": prediction,
			"Dye 1": dye1,
			"Dye 1 Conc (g/L)": dye1conc,
			"Dye 2": dye2,
			"Dye 2 Conc (g/L)": dye2conc,
			"Dye 3": dye3,
			"Dye 3 Conc (g/L)": dye3conc,
			"NPA(%)": npa,
			"TBAC(g/L)": tbac,
			"L": L,
			"a": a,
			"b": b,
			"∆E": dE,
			"R": R,
			"G": G,
			"B": B,
			"∆L": DL,
			"∆a": Da,
			"∆b": Db,
			"±Mean": umean,
			"±L": uL,
			"±a": ua,
			"±b": ub,
			...rest
		}));

		const workbook = XLSX.utils.book_new();
		const sheet = XLSX.utils.json_to_sheet(newResData);
		XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
		const excelBlob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })]);

		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(excelBlob);
		downloadLink.download = `LAB_TO_DCS_${L}_${a}_${b}.xlsx`
		downloadLink.click();
	};

	const handleResponse = (response) => {
		let new_rows = [];
		let counter = 0;
		let length = 29;
		if (typeof response !== "undefined") {
			for (let i = 0; i <= length; i++) {
				if (response["Dye 1"][i] == 'BemacidRedF-GS' || response["Dye 1"][i] == 'TectilonYellow2G' || response["Dye 1"][i] == 'ErionylRed2B' || response["Dye 2"][i] == 'BemacidRedF-GS' || response["Dye 2"][i] == 'TectilonYellow2G' || response["Dye 2"][i] == 'ErionylRed2B' || response["Dye 3"][i] == 'BemacidRedF-GS' || response["Dye 3"][i] == 'TectilonYellow2G' || response["Dye 3"][i] == 'ErionylRed2B'){
					length++;
				}
				else {
					counter++;
					myGroups.admin
						? (new_rows = [
							...new_rows,
							createAdminData(
								counter,
								response["NPA %"][i],
								response["TBAC gL"][i],
								response["Dye 1"][i],
								response["Conc1 gL"][i],
								response["Dye 2"][i],
								response["Conc2 gL"][i],
								response["Dye 3"][i],
								response["Conc3 gL"][i],
								response["L"][i],
								response["a"][i],
								response["b"][i],
								response["Delta E"][i],
								response["R"][i],
								response["G"][i],
								response["B"][i],
								response["DL*"][i],
								response["Da*"][i],
								response["Db*"][i],
								response["Uncertainty Mean"][i],
								response["Uncertainty L"][i],
								response["Uncertainty a"][i],
								response["Uncertainty b"][i]
							),
						])
						: (new_rows = [
							...new_rows,
							createUserData(
								counter,
								response["NPA %"][i],
								response["TBAC gL"][i],
								response["Dye 1"][i],
								response["Conc1 gL"][i],
								response["Dye 2"][i],
								response["Conc2 gL"][i],
								response["Dye 3"][i],
								response["Conc3 gL"][i],
								response["L"][i],
								response["a"][i],
								response["b"][i]
							),
						]);
				}
			}
			setRows(new_rows);
			setButtonDisabled(false);
		} else alert("Error: Something went wrong. Try Again.");
	};


	return (
		<div className="lab-page-flex mt12-sm">
			<div className="page-title u-full-width d-sm-flx flx-jc-sm-c">
				<Filter className="mr2-sm" style={{ height: "55px", width: "55px" }} />
				<span className="display-1">LAB</span>
				<FontAwesomeIcon className="mr2-sm ml2-sm" icon={faLongArrowAltRight} />
				<Measure className="mr2-sm" style={{ height: "55px", width: "55px" }} />
				<span className="display-1">DCS</span>
			</div>
			<div className={"lab-top-slab"}>
				<form className={"lab-form"} noValidate autoComplete="off">
					<div className="d-sm-flx flx-jc-sm-sa mb12-sm">
						<TextField
							value={L}
							onChange={(event) => {
								if (event.target.value >= 0 && event.target.value <= 100) {
									let RGB = lab2rgb(
										Number(event.target.value),
										parseInt(a),
										parseInt(b)
									);
									setL(Number(event.target.value));
									setR(RGB.R);
									setG(RGB.G);
									setB(RGB.B);
								}
							}}
							id="L"
							name="L"
							label="L"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
						/>
						<TextField
							value={a}
							onChange={(event) => {
								if (event.target.value === "-") {
									let RGB = lab2rgb(L, parseInt(0), parseInt(b));
									seta("-");
									setR(RGB.R);
									setG(RGB.G);
									setB(RGB.B);
								} else if (event.target.value === "") {
									let RGB = lab2rgb(L, parseInt(0), parseInt(b));
									seta("");
									setR(RGB.R);
									setG(RGB.G);
									setB(RGB.B);
								} else if (
									event.target.value >= -100 &&
									event.target.value <= 100
								) {
									let RGB = lab2rgb(
										L,
										parseInt(event.target.value),
										parseInt(b)
									);
									seta(Number(event.target.value));
									setR(RGB.R);
									setG(RGB.G);
									setB(RGB.B);
								}
							}}
							id="a"
							name="a"
							label="a"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
						/>
						<TextField
							value={b}
							onChange={(event) => {
								if (event.target.value === "-") {
									let RGB = lab2rgb(L, parseInt(a), parseInt(0));
									setb("-");
									setR(RGB.R);
									setG(RGB.G);
									setB(RGB.B);
								} else if (event.target.value === "") {
									let RGB = lab2rgb(L, parseInt(a), parseInt(0));
									setb("");
									setR(RGB.R);
									setG(RGB.G);
									setB(RGB.B);
								} else if (
									event.target.value >= -100 &&
									event.target.value <= 100
								) {
									let RGB = lab2rgb(
										L,
										parseInt(a),
										parseInt(event.target.value)
									);
									setb(Number(event.target.value));
									setR(RGB.R);
									setG(RGB.G);
									setB(RGB.B);
								}
							}}
							id="b"
							name="b"
							label="b"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
						/>
						<div
							className={"lab-color-box"}
							style={{ backgroundColor: `rgb(${R},${G},${B})` }}
						/>
					</div>

					<div className="d-sm-flx flx-jc-sm-sa flx-ai-sm-c">
						<FormControl>
							<InputLabel id="npainput">NPA value</InputLabel>
							<Select
								value={npa}
								onChange={(event) => {
									setNpa(event.target.value);
								}}
								id="npa"
								name="npa"
							>
								<MenuItem value={0.15}>15%</MenuItem>
								<MenuItem value={0.2}>20%</MenuItem>
								<MenuItem value={0.25}>25%</MenuItem>
								<MenuItem value={0.3}>30%</MenuItem>
								<MenuItem value={0.35}>35%</MenuItem>
								<MenuItem value={0.4}>40%</MenuItem>
							</Select>

							<FormHelperText>*Required Field above</FormHelperText>
						</FormControl>
						<FormControl>
							<InputLabel id="dyetbacinput">DYE:TBAC</InputLabel>
							<Select
								value={dyetbac}
								onChange={(event) => {
									setDyetbac(event.target.value);
								}}
								id="dyetbac"
								name="dyetbac"
							>
								<MenuItem value={1}>1:1</MenuItem>
								<MenuItem value={2}>2:1</MenuItem>
								<MenuItem value={3}>3:1</MenuItem>
								<MenuItem value={4}>4:1</MenuItem>
							</Select>
							<FormHelperText>*Required Field above</FormHelperText>
						</FormControl>
						<FormControl>
							<InputLabel>DYE COUNT</InputLabel>
							<Select
								value={dyecombination}
								onChange={(event) => {
									setDyecombinations(event.target.value);
									if (event.target.value === 1) {
										setTimer(2);
									} else if (event.target.value === 2) {
										setTimer(5);
									} else {
										setTimer(15);
									}
								}}
								id="dyecombination"
								name="dyecombination"
							>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>Up to 2</MenuItem>
								<MenuItem value={3}>Up to 3</MenuItem>
							</Select>
							<FormHelperText>*Required Field above</FormHelperText>
						</FormControl>
						<div className="display-download">
						<ButtonStyled
							style={{ height: "0px", width: "200px" }}
							background="light"
							size="medium"
							ariaLabel="label"
							appearance="primary"
							disabled={buttonDisabled}
							onClick={submitForm}
						>
							Submit
						</ButtonStyled>
						{rows && rows.length > 0?
						<FontAwesomeIcon className="download-button" icon={faDownload} onClick={handleDownload} />: null }
					</div>
					</div>
				</form>
				{helperConfig ? (
					<div className="d-sm-flx flx-jc-sm-c">
						<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c mt10-sm">
							<div className="headline-2 mr12-sm">Iris File</div>
							<div className="body-2 mr12-sm">{helperConfig.iris}</div>
						</div>
						<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c mt10-sm">
							<div className="headline-2 mr12-sm">Dye List File</div>
							<div className="body-2 mr12-sm">{helperConfig.dyeList}</div>
						</div>
						<div className="d-sm-flx flx-dir-sm-c flx-ai-sm-c mt10-sm">
							<div className="headline-2">Dye Limit File</div>
							<div className="body-2">{helperConfig.dyeLimit}</div>
						</div>
					</div>
				) : null}
			</div>
			<div className="lab-bottom-slab mr12-sm ml12-sm mb12-sm">
				{buttonDisabled === true ? (
					<Timer hoursMinSecs={{ hours: 0, minutes: timer, seconds: 0 }} />
				) : null}
				{rows.length > 0 && (
					<TableContainer component={Paper}>
						<Table size="small" stickyHeader aria-label="sticky table">
							<LABTableHeader
								titles={
									myGroups.admin
										? [
												"Rank",
												"NPA(%)",
												"TBAC(g/L)",
												"Dye 1",
												"Dye 1 Conc (g/L)",
												"Dye 2",
												"Dye 2 Conc (g/L)",
												"Dye 3",
												"Dye 3 Conc (g/L)",
												"L",
												"a",
												"b",
												"∆E",
												"R",
												"G",
												"B",
												"∆L",
												"∆a",
												"∆b",
												"±Mean",
												"±L",
												"±a",
												"±b",
												"Color",
										  ]
										: [
												"Rank",
												"NPA(%)",
												"TBAC(g/L)",
												"Dye 1",
												"Dye 1 Conc (g/L)",
												"Dye 2",
												"Dye 2 Conc (g/L)",
												"Dye 3",
												"Dye 3 Conc (g/L)",
												"L",
												"a",
												"b",
												"Color",
										  ]
								}
							/>
							<TableBody>
								{rows && rows.length > 0
									? rows.map((row) => (
											<LABTableRow row={row} groups={myGroups} />
									  ))
									: null}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</div>
		</div>
	);
};
export default FromLab;
